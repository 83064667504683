import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from '../components/breadcrumb'
import { HeaderBannerProjectsPg } from "../components/Projects-Page/header-banner-projects"
import { ProjectsSectionProjectPg } from "../components/Projects-Page/projects-section-projectPg"
import { MetricsSectionHomePg } from "../components/Home-Page/metrics-section-homePg"
import { CoreValuesSectionHomePg } from "../components/Home-Page/core-values-section-homePg"

const ProjectsPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Kaylar Construction Projects: Inspiring Kitchen Remodeling, Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, and Interior Design Transformations"
                description="Experience our stunning projects showcase! Discover exceptional kitchen remodeling, bathroom remodeling, siding, windows & doors, decks & patios, and interior design transformations. Get inspired and envision the possibilities for your own home."
                location={props.location.pathname}
                keywords={[
                    'Kaylar Construction Projects',
                    'Kitchen remodeling',
                    'Bathroom remodeling',
                    'Siding',
                    'Windows and doors',
                    'Decks and patios',
                    'Interior design services'
                ]}
            />
            <div>
                <HeaderBannerProjectsPg />
                <div className="bg-white border border-gray-200 shadow-lg">
                    <nav className="flex container mx-auto px-4 pt-1 pb-3" aria-label="Breadcrumb">
                        <Breadcrumb links={[
                            { to: '/', label: 'Home' },
                            { to: '/projects', label: 'Projects' },
                        ]} />
                    </nav>
                </div>
                <ProjectsSectionProjectPg data={props.data.allContentfulProjects.edges} />
                <MetricsSectionHomePg />
                <CoreValuesSectionHomePg />
            </div>
        </Layout>
    )
}
export default ProjectsPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulProjects(sort: { fields: date, order: DESC }) {
        edges{
            node{
                slug
                mainImage {
                    title
                    description
                    url
                    gatsbyImageData (
                      placeholder: NONE
                    )
                  }
                city
                serviceName
                paragraph1 {
                    paragraph1
                }
                sliderImages {
                    id
                    description
                    gatsbyImageData
                    url
                    title
                    description
                }
                date               
            }
        }
    }
}
`